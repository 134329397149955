<template>

<div class="relative">
    <div class="prediction-bg">
        <img class="w-100 h-100" src="../../../../static/images/background_event.png">
    </div>
    <div class="container display-flex flex-col has-space mt-m">
        <div class="tc font-m mb-05rem bold" v-if="isMobile">{{contestInfo.contestDescription}}</div>
        <div class="mb-2rem">
            <div class="prediction-banner__contest show scroll-from-top">
                <img class="img-cover scale-fix" :src="`${s3ImgUrl}${contestInfo.contestBanner}`">
                <div class="prediction-banner__contest-overlay"></div>
            </div>
            <div class="prediction-banner__prize scroll-from-bottom">
                <!-- grand prize banner -->
                <div class="prediction-banner__prize-card" v-if="contestInfo.grandPrizeImage !==''">
                    <img class="img-cover" :src="`${s3ImgUrl}${contestInfo.grandPrizeImage}`">
                    <div class="prediction-banner__prize-card__overlay">
                        <span v-html="contestInfo.grandPrizeHover"></span>
                    </div>
                </div>
                <!-- lucky draw prize -->
                <div class="prediction-banner__prize-card">
                    <img class="img-cover" :src="`${s3ImgUrl}${contestInfo.luckyDrawPrizeImage}`">
                    <div class="prediction-banner__prize-card__overlay">
                        <span v-html="contestInfo.luckyDrawPrizeHover"></span>
                    </div>
                </div>
            </div>
        </div>

        <!-- countdown -->
        <div class="prediction-countdown__wrapper scroll-from-top">
            <div class="display-flex-center">
                <div class="prediction-countdown__title">
                    <span v-if="!isEventEnded" >{{$t(countDownPrefix)}}</span>
                    <div v-else>{{$t('EVENT_ENDED')}}</div>
                </div>
                <div v-if="!isEventEnded" class="prediction-countdown">
                    <div class="prediction-countdown__time" v-if="eventTime.days !== '00'">
                        <span class="prediction-countdown__time-time">{{eventTime.days}}</span>
                        <span class="prediction-countdown__time-day">{{$t('DAYS')}}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"  v-if="eventTime.days !== '00'"><span>:</span></div>

                    <div class="prediction-countdown__time">
                        <span class="prediction-countdown__time-time">{{eventTime.hours}}</span>
                        <span class="prediction-countdown__time-day">{{$t('HRS')}}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"><span>:</span></div>

                    <div class="prediction-countdown__time">
                        <span class="prediction-countdown__time-time">{{eventTime.min}}</span>
                        <span class="prediction-countdown__time-day">{{$t('MINS')}}</span>
                    </div>
                    <div class="prediction-countdown__time-seperator"  v-if="eventTime.days === '00'"><span>:</span></div>

                    <div class="prediction-countdown__time"  v-if="eventTime.days === '00'">
                        <span class="prediction-countdown__time-time">{{eventTime.sec}}</span>
                        <span class="prediction-countdown__time-day">{{$t('SECS')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="display-flex-center flex-col scroll-from-top">
            <h4 class="prediction-entry__title tc" >{{$t('PREDICTION_ENTRY')}}</h4>
           <div class="prediction-entry__desc" >{{contestInfo.question}}</div>  
        </div>


        <div class="prediction-team__wrapper scroll-from-top" >
            <div class="prediction-team__container" :class="{'pe-none': isJoinedEvent || timeType === 3 || isEventEnded}">
                <!-- team a -->
                <div class="prediction-team" >
                    <div class="prediction-team" @click="showModalTeam('teamA')">
                        <div class="prediction-team__name">
                            <span class="mr-03rem">{{$t('TEAM')}}</span><span>A</span>
                        </div>
                        <div class="prediction-team__choose">
                            <img v-if="team.teamA.id ===''" class="w-100 h-100 img-contain" src="../../../../static/images/team_logo_default.png">
                            <Icon v-else :category="'team'" :id="team.teamA.id" :hasClass="'w-100 h-100'"></Icon>
                        </div>
                        <div class="prediction-team__desc">
                            <span v-if="team.teamA.id ==''">{{$t('CHOOSE_A_TEAM')}}</span>
                            <span v-else>{{team.teamA.name}}</span>
                        </div>
                    </div>
                    <div class="prediction-team__score" v-if="isMobile"><input v-model="team.teamA.score" v-on:keyup="inputTeamAScoreEvent" pattern="[0-9]*" type="text"   placeholder="-"></div>
                </div>

                <!-- score -->
                <div class="prediction-team__score-wrapper">
                    <div v-if="!isMobile" class="prediction-team__score"><input v-model="team.teamA.score" v-on:keyup="inputTeamAScoreEvent" type="number" placeholder="-"></div>
                    <div class="prediction-team__vs">VS</div>
                    <div v-if="!isMobile" class="prediction-team__score"><input v-model="team.teamB.score" v-on:keyup="inputTeamBScoreEvent"  type="number"  placeholder="-"></div>
                </div>

                <!-- team b -->
                <div class="prediction-team" >
                    <div class="prediction-team" @click="showModalTeam('teamB')">
                        <div class="prediction-team__name">
                            <span class="mr-03rem">{{$t('TEAM')}}</span><span>B</span>
                        </div>
                        <div class="prediction-team__choose" >
                            <img v-if="team.teamB.id ===''"  class="w-100 h-100 img-contain" src="../../../../static/images/team_logo_default.png">
                            <Icon v-else :category="'team'" :id="team.teamB.id" :hasClass="'w-100 h-100'"></Icon>
                        </div>
                        <div class="prediction-team__desc">
                            <span v-if="team.teamB.id ==''">{{$t('CHOOSE_A_TEAM')}}</span>
                            <span v-else>{{team.teamB.name}}</span>
                        </div>
                    </div>
                      <div class="prediction-team__score" v-if="isMobile"><input v-model="team.teamB.score" v-on:keyup="inputTeamBScoreEvent" pattern="[0-9]*" type="text"   placeholder="-"></div>
                </div>
            </div>

            <SocialMediaAndTermCondition @changeTickEvent2="changeTickEvent2($event)" :isTick="isJoinedEvent || isBtnSubmitEnable"></SocialMediaAndTermCondition>
            
            <!-- submit button -->
            <div class="prediction-team__btn-submit">
                <button @click="handleBtnSubmit" class="btn btn-primary disable w-100" :class="{'enable':isBtnSubmitEnable}">{{$t('SUBMIT')}}</button>
            </div>
              
            <!-- term and condition -->
            <div class="prediction-team__term_condition btn--underline" @click="showModalTermCondition()">{{$t('TERMS_&_CONDITIONS')}}</div>
        </div>

        <div class="scroll-from-top">
            <h4 class="prediction-record tc">{{$t('PREDICTION_RECORDS')}}</h4>
        </div>

        <div class="mb-1rem display-flex-center md:flex-start scroll-from-top">
            <div class="tabs selected">
                <span class="tabs-name">{{$t('LATEST_PREDICTIONS')}}</span>
                <span class="tabs-line"></span>
            </div>       
            <!-- <div class="tabs">
                <span class="tabs-name">{{$t('MY_PREDICTIONS')}}</span>
                <span  class="tabs-line"></span>
            </div> -->
        </div>

        <div class="prediction-table scroll-from-bottom">
            <div class="prediction-table__header">
                <div class="prediction-table__header-title  tc"><span>{{$t('NICKNAME')}}</span></div>
                <div class="prediction-table__header-title title-2 "><span class="prediction-table__header-title-name">{{$t('PREDICTIONS')}}</span></div>
            </div>
            <div class="prediction-table__body">
                <li v-show="timeType !== 3" class="prediction-table__body-list" v-for="(o, index) in latestPredictionListResult" :key="index">
                    <span class="prediction-table__body-list__child-1 ">{{o.nickName}}</span>
                    <div class="display-flex prediction-table__body-list__child-2">
                        <div class="prediction-table__body-list__result">
                            <div class="prediction-table__body-list__result-name">{{o.homeTeam}}</div>
                            <span>[{{o.homeGoal}}]</span>
                        </div>
                        <div class="display-flex-align-center">
                            <span class="prediction-table__body-list__result-score-2">[{{o.awayGoal}}]</span>
                            <div class="prediction-table__body-list__result-name-2">{{o.awayTeam}}</div>
                        </div>
                    </div>
                    <div  class="prediction-table__body-list__divider"></div>
                </li>   
                <div v-show="timeType === 3" class="prediction-table__no-data">{{$t('NO_PREDICTIONS')}}</div>       
            </div>
            <div class="prediction-table__footer">
                <!-- <MobilePagination :params="dataPagination" @changeNextPrevEvent ="changePaginationEvent($event)"></MobilePagination> -->
            </div>
        </div>
    </div>
</div>
    <!-- select team desktop -->
    <ModalPopup ref="modalPopupSelectTeamRef" :modalSize="'large'">
        <div class="h-100">
            <h3 class="tc mb-15rem">{{$t('CHOOSE_YOUR_TEAM')}}</h3>
            <div class="prediction-modal__team-search__wrapper">
                <img class="prediction-modal__team-search__icon" src="../../../../static/images/icons/icon_search.png">
                <input :placeholder="$t('SEARCH_TEAM')" v-model="input_search_team" class="prediction-modal__team-search__input" v-on:keyup="searchTeamEvent">
            </div>
            <perfect-scrollbar class="perfect-scrollball__medium">
                <div class="prediction-modal__team-icon__wrapper">
                    <div v-for="(o, index) in modalTeamList" :key="index"  @click="handleSelectTeamModal(o)" class="prediction-modal__team-icon__container" v-show="o.isShow" >
                        <Icon :category="'team'"  :id="o.teamId" class="prediction-modal__team-icon" :class="{selected: o.selected}"></Icon>
                        <div class="prediction-modal__team-name">{{o.teamName}}</div>
                    </div>               
                </div>
              </perfect-scrollbar>
            <div class="mt-15rem">
                <button class="btn btn-primary w-100 transparent-disable" :class="{'enable': isModalTeamBtnConfirmEnable}" @click="modalTeamConfirm">{{$t('CONFIRM')}}</button>
            </div>
        </div>
    </ModalPopup>
    <!-- select team mobile -->
    <MobileSlide ref="mobileSlideSelectTeamRef" :title="'CHOOSE_YOUR_TEAM'" :isSelect="isModalTeamBtnConfirmEnable"  @finishSelectEvent="modalTeamConfirm($event)" isShowTick="true">
        <div class="prediction-modal__team-search__wrapper">
            <img class="prediction-modal__team-search__icon" src="../../../../static/images/icons/icon_search.png">
            <input :placeholder="$t('SEARCH_TEAM')" v-model="input_search_team" class="prediction-modal__team-search__input" v-on:keyup="searchTeamEvent">
        </div>
            <div class="prediction-modal__team-icon__wrapper" >
                <div v-for="(o, index) in modalTeamList" :key="index"  @click="handleSelectTeamModal(o)" :class="{selected: o.selected}" class="prediction-modal__team-icon__container" v-show="o.isShow" >
                    <Icon :category="'team'" :id="o.teamId" class="prediction-modal__team-icon" ></Icon>
                    <div class="prediction-modal__team-name">{{o.teamName}}</div>
                </div>               
            </div>
    </MobileSlide>

    <!-- sign up info -->
    <ModalPopup ref="modalPopupSubmitRef">
        <div class="display-flex-align-center flex-col modal-confirmation__body">
            <div class="mb-15rem font-m tc">{{$t('EVENT_PREDICTION_SUBMIT_INFO')}}</div>
             <div v-if="isMobile" class="w-100">
                <router-link :to="{ name: 'signup' , query: { callBackUrl: currentPathName }}"> <button class="btn w-100 mb-1rem bg-white">{{$t('SIGNUP')}}</button></router-link>
                 <router-link :to="{ name: 'signin' , query: { callBackUrl: currentPathName }}"><button class="btn btn-outline w-100">{{$t('LOGIN')}}</button></router-link>
            </div>
            <div v-else class="w-100">
                <button class="btn w-100 mb-1rem bg-white" @click="handleAuthentication('signUp')">{{$t('SIGNUP')}}</button>
                <button class="btn btn-outline w-100" @click="handleAuthentication('signIn')">{{$t('LOGIN')}}</button>
            </div>
        </div>
    </ModalPopup>

    <PopUpSignIndex ref="modalPopupSignInRef"  @handleCloseEvent="handleCloseEventSignInRef"  @loginSucuessEvent="loginSucuessEvent($event)"></PopUpSignIndex>
    
  <!-- term and condition desktop-->
   <ModalPopup ref="modalPopupRef" >
        <h3 class="tc mt-15rem mb-15rem">{{$t('TERMS_&_CONDITIONS')}}</h3>
        <perfect-scrollbar class="perfect-scrollball__medium">
            <div class="term-body" v-html="contestInfo.tnc"></div>
        </perfect-scrollbar>
    </ModalPopup>
    
    <!-- term and condition mobile-->
    <MobileSlide ref="mobileSlideRef" :title = "'TERMS_&_CONDITIONS'">
        <div class="pb-1rem term-body" v-html="contestInfo.tnc"></div>
    </MobileSlide>

</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import moment from 'moment'

import MobilePagination from '@/components/table/MobilePagination.vue'
import SocialMediaAndTermCondition from '@/components/event/SocialMediaAndTermCondition.vue'
import ModalPopup from '@/components/modal/ModalPopup.vue'
import MobileSlide from '@/components/modal/MobileSlide.vue'
import config from '@/js/config.js'

import PopUpSignIndex from '@/components/user/PopUpSignInIndex.vue'
export default {
    components:{
        ModalPopup,
        MobileSlide,
        SocialMediaAndTermCondition,
        MobilePagination,

        PopUpSignIndex
    },

    data() {
        return {
            s3ImgUrl: config.s3ImgUrl,
            currentPathName: '',
            countDownPrefix: "",

            eventTime: {
                days: '',
                hours: '',
                min: '',
                sec: ''
            },

            isEventEnded: false,
            isJoinedEvent: false,

            isBtnSubmitEnable: false,
            isModalTeamBtnConfirmEnable: false,

            modalTeamList: [],
            contestInfo: {},
          
            team: {
                teamA: {
                    score: "",
                    id: '',
                    name: ''
                },
                teamB: {
                    score: "",
                    id: '',
                    name: ''
                },
            },
            agreeTermCondition: false,

            mobile: {
                isShowTermCondition: false
            },

            countDownInterval: null,
            latestPredictionList: [],

            latestPredictionListResult:[],
            page: 1,

            dataPagination: {},
            pageSize: 5,
            currentPage: 1,
            totalPage: 0,
            showUpto: 5,
            showFromto: 0,

            isLoginFromPopUp: false,
            redirectFrom:'',
            storeDataPath: {},
        }
    },
    
    watch:{
        isLogin: {
            deep: true,
            handler(newVal, preVal) {
                // if (newVal ===  false) {
                    //this.init();
                // } else { // comment out due to AS-1432 logic - Negative case 2.2
                //     this.getMyPredictionListData(); 
                // }
                //
            }
        },
        currentLocale: {
            deep: true,
            handler(prev, nesw) {
              //this.init(); 
            }
        },
        currentLanguageObj: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.displayName === "English") {
                    //this.init();
                }
            }
        },
        currentCountryObj: {
            deep: true,
            handler(prev, nesw) {
              //this.init(); 
            }
        },
         userSwitchCountryLang: {
            deep: true,
            handler(prev, nesw) {
                this.$router.push(`/${this.currentLanguageObj.displayLocale}/event`);  
                //this.init();
            }
        }
   },

    computed: {
        ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentLanguageObj',
                'isLogin',
                'currentCountryObj',
                'userSwitchCountryLang'
           ]),
    },

    mounted() {
        this.commonVerifyLogin();
        this.init();
        window.sr = ScrollReveal();
        this.$nextTick(() => {
            sr.reveal('.scroll-from-left', {
                origin: 'left' ,
                duration: 1000,
                easing: 'ease-out' ,
                distance: '500px',
                delay: 100,
                        
            });
                sr.reveal('.scroll-from-right', {
                    origin: 'right' ,
                    duration: 1000,
                    easing: 'ease-out' ,
                    distance: '200px',
                    delay: 100     ,
                    
                });
                sr.reveal('.scroll-from-bottom', {    
                    duration: 1000,
                    distance: '50px',
                    delay: 100              
                });

                sr.reveal('.scroll-from-top', {    
                    origin: 'top' ,
                    duration: 1000,
                    distance: '200px',
                    delay: 100              
                });
                sr.reveal('.scroll-from-opacity', {    
                    distance: '200px',
                        opacity: 0.8,
                        duration: 1000,
                });
                sr.reveal('.scroll-from-scaleUp', {    
                    distance: '200px',
                scale: 0.85,
                duration: 1000,
                });
        });

    },
  
    beforeUnmount() {
        clearInterval(this.countDownInterval);
    },
    methods: {
        ...mapActions([
            "getContestInfo",
            "getTeamListByContestId",
            "getContestJoin",
            "getContesterPredictionList",
            "getMyPredictionList",
            "getLatestContesterPredictionList",
            "popUpSignInModuleEvent",
            "navigationMenuEvent",
            "getContentList",
            "toastrChangeEvent",
            "getCountryCodeByIp",
            "currentCountryChangeEvent",
            "commonVerifyLogin",
        ]),
        myCallback(pageOfItems) {
            // update page of items         
            this.pageOfItems = pageOfItems;
        },
        async init() { 
            //get contest id
            this.contestId = this.$route.params.contestId;
            this.currentPathName = window.location.pathname;

            if(!this.isLogin && !this.userCountryCode) {
                let result = await this.getCountryCodeByIp();
                if (result.message === "Success") {
                    this.currentCountryChangeEvent(result.result.countryCode);
                }
            }
            this.getContestInfoData();
            this.getTeamListByContestIdData();

            this.getContesterPredictionListData();
            this.getMyPredictionListData(); 

            this.getLatestContesterPredictionListData();
        },

        async getContestInfoData() {
            let params = {
               contestId: this.contestId,
               language: this.currentLanguageObj.locale,
               lang: this.currentLanguageObj.apiParam,
            }
          
            const result = await this.getContestInfo(params);

            if (result.result === null) {   
                this.$router.push(`/${this.currentLanguageObj.displayLocale}/event`);
                return;
            } else {
                this.contestInfo =  result.result;
            }

            this.timeType = this.contestInfo.timeType;
         
            if (this.timeType === 2) {
                //ONGOING
                this.countDownPrefix = "ENDING_IN";  
                this.updateContestInfoDate(result.result.endDate);
            } else if (this.timeType === 3) {
                //UPCOMING
                this.countDownPrefix = "STARTING_IN";
                this.updateContestInfoDate(result.result.startDate);
            } else {
                this.isEventEnded = true;
                this.countDownPrefix = "EVENT_ENDED"          
            }
        },

        //countdown contest end date 
        updateContestInfoDate(endDate) {      
            var t = new Date();
            t.setSeconds(t.getSeconds() + 10);            
            let countDownDate = new Date(endDate);
            // Get today's date and time
          
            let now = moment.utc().valueOf();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;

            if (distance < 0) {
                this.isEventEnded = true;
                return;
            }
            // Time calculations for days, hours, minutes and seconds
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            this.eventTime.days = days<10 ? '0'+days: days;
            this.eventTime.hours =  hours<10 ? '0'+hours: hours;
            this.eventTime.min = minutes<10 ? '0'+minutes: minutes;
            this.eventTime.sec = seconds<10 ? '0'+seconds: seconds;
            // Update the count down every 1 second
            
            this.countDownInterval = setInterval(() => {              
                    // Get today's date and time
                     let now = moment.utc().valueOf();
                 
                    // Find the distance between now and the count down date
                    let distance = countDownDate - now;

                    // Time calculations for days, hours, minutes and seconds
                    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    // Display the result in the element with id="demo"
                    this.eventTime.days = days < 10 ? '0'+ days: days;
                    this.eventTime.hours =  hours < 10 ? '0'+ hours: hours;
                    this.eventTime.min = minutes < 10 ? '0'+ minutes: minutes;
                    this.eventTime.sec = seconds < 10 ? '0'+ seconds: seconds;
                    
                    // If the count down is finished, write some text
                    if (distance <= 1) {
                       
                        if (this.timeType === 3) {
                            //UPCOMING
                            this.init();                          
                        } else if (this.timeType === 2) {
                            //ONGOING
                             this.isEventEnded = true;
                        }
                        
                        clearInterval(this.countDownInterval);
                    }
            }, 1000);

        },
        
        //get team list
        async getTeamListByContestIdData() {
            let params = {
                contestId: this.contestId
            }
            const result = await this.getTeamListByContestId(params);

            result.result.forEach(x => {
                x.selected = false;
                x.isShow = true;
            });

            this.modalTeamList = result.result;
        },

        handleSelectTeamModal(o) { 
            this.modalTeamList.forEach(x => {
                x.selected= false;
            });
            o.selected = true;

            this.currentTeamSelected = o;
           
            this.isModalTeamBtnConfirmEnable = true;
        },

        //show modal pop up select team
        showModalTeam(target) {
            this.showCurrentTeam = target;

            //show selected team
            if (this.team[this.showCurrentTeam].id !== '') {
                 this.modalTeamList.forEach(x => {
                     if (x.teamId === this.team[this.showCurrentTeam].id) {
                        x.selected = true;
                     } else {
                        x.selected = false;
                     }   

                     this.isModalTeamBtnConfirmEnable = true;              
                });
            } else {
                 this.modalTeamList.forEach(x => {
                    x.selected= false;
                });
                this.isModalTeamBtnConfirmEnable = false;
            }

            if (this.isMobile) {
                this.$refs.mobileSlideSelectTeamRef.handleOpen();
            } else {
                this.$refs.modalPopupSelectTeamRef.handleOpen();
            }
       
        },

        //show modal pop up term & condition
        showModalTermCondition() {
            if (this.isMobile) {
                this.$refs.mobileSlideRef.handleOpen();
            } else {
                 this.$refs.modalPopupRef.handleOpen();
            }
        },


        //modal pop up team handle confirm button
        modalTeamConfirm() {
            //bind selected team data to team variable
            this.team[this.showCurrentTeam].id = this.currentTeamSelected.teamId;
            this.team[this.showCurrentTeam].name = this.currentTeamSelected.teamName;

            this.verifyAllInputFilled();
            this.$refs.modalPopupSelectTeamRef.handleClose();
        },

        // verify score and team are filled or not
        verifyAllInputFilled() {            
            if (this.team.teamA.score !== ''  && this.team.teamB.score !== '' && this.team.teamA.id !== '' && this.team.teamB.id !== '' && this.agreeTermCondition) {

                if (this.isJoinedEvent || this.isEventEnded) {
                    this.isBtnSubmitEnable = false;
                } else {
                    this.isBtnSubmitEnable = true;
                }

                this.currentPathName = `${this.currentPathName}?homeGoal=${this.team.teamA.score}&awayGoal=${this.team.teamB.score}&homeTeamId=${this.team.teamA.id}&awayTeamId=${this.team.teamB.id}&homeTeam=${this.team.teamA.name}&awayTeam=${this.team.teamB.name}`
                this.storeDataPath = {
                    homeGoal: this.team.teamA.score,
                    awayGoal: this.team.teamB.score,
                    homeTeamId: this.team.teamA.id,
                    awayTeamId: this.team.teamB.id,
                    homeTeam: this.team.teamA.name,
                    awayTeam: this.team.teamB.name
                };
           } else {
                this.isBtnSubmitEnable = false;
            }
        },

        inputTeamAScoreEvent() {
            this.team.teamA.score = this.$tools.onlyNumber(this.team.teamA.score);

            this.verifyAllInputFilled();
        },
        
        inputTeamBScoreEvent() {
            this.team.teamB.score = this.$tools.onlyNumber(this.team.teamB.score);

            this.verifyAllInputFilled();
        },

        searchTeamEvent() {
            if (this.input_search_team === '') {
               this.modalTeamList.forEach(x => {
                    x.isShow = true;
                })
            } else {
                  for (let i = 0; i < this.modalTeamList.length; i++) {
                    if (this.modalTeamList[i].teamName.trim().toUpperCase().search(this.input_search_team.trim().toUpperCase()) > -1) {
                        this.modalTeamList[i].isShow = true;
                    } else {
                        this.modalTeamList[i].isShow = false;
                    }
                } 
            }  
        },

        loginSucuessEvent(data) {
            this.$refs.modalPopupSignInRef.handleClose();
            
            this.redirectFrom = data;
            this.init();    
        },

        handleCloseEventSignInRef() {
           this.isLoginFromPopUp = false;
        },
        
        handleAuthentication(type) {
            this.popUpSignInModuleEvent(type);
            this.$refs.modalPopupSignInRef.handleOpen();
            this.$refs.modalPopupSubmitRef.handleClose(); 
        },

        async getContentListData() {
            let params = {
                timeType: 2,
                language: this.currentLanguageObj.locale,
                lang: this.currentLanguageObj.apiParam,
            }
         
            const result = await this.getContentList(params);

            let returnResult = result.result;

            this.navigationMenuEvent(returnResult);
        },

        async handleBtnSubmit() {
            let redirectFrom = this.redirectFrom;
            
            if (!this.isLogin) {
                this.$refs.modalPopupSubmitRef.handleOpen();          
            } else {
               
                if (this.team.teamA.id === this.team.teamB.id) {
                       let paramsToastr={
                        isOpen:true,
                        msg: 'TEAM_A_TEAM_B_SAME_INFO',
                        type:'info'       
                    }
                    this.toastrChangeEvent(paramsToastr);
                    // this.$root.toastrMsg = 'TEAM_A_TEAM_B_SAME_INFO';
                    // this.$root.toastrType = 'info';
                    // this.$root.$refs.toastrRef.handleOpen();
                    return;
                }

                let params = {
                    awayTeamName: this.team.teamB.name,
                    awayTeamId: this.team.teamB.id,
                    awayScore: this.team.teamB.score,
                    contestId: this.contestId,
                    homeScore: this.team.teamA.score,
                    homeTeamName: this.team.teamA.name,
                    homeTeamId: this.team.teamA.id
                }
                
            
                const result = await this.getContestJoin(params);
          
                if (result.result.businessCode === 0) {
                    this.isLoginFromPopUp = false;
                    this.getContentListData();
                    this.handleJoinedEvent();

                    // update latest prediction list
                    this.getContesterPredictionListData();

                    //show sucuessful message
                    let toastrMsg = "SUBMITTED_SUCCESSFULLY";
                    if (redirectFrom === 'signIn') {
                        toastrMsg = 'PREDICTION_SUBMITTED_SUCCESSFULLY_POPUP_SIGN_IN';
                    } else if (redirectFrom === 'signUp') {
                        toastrMsg = 'PREDICTION_SUBMITTED_SUCCESSFULLY_POPUP_SIGN_UP';
                    } else if (redirectFrom === 'forgetPassword') {
                        toastrMsg = 'PREDICTION_SUBMITTED_SUCCESSFULLY_POPUP_PASSWORD_CHANGE';
                    }
                    let paramsToastr={
                        isOpen:true,
                        msg: toastrMsg,
                        type:''       
                    }
                    this.toastrChangeEvent(paramsToastr);
                    // this.$root.toastrMsg = toastrMsg;
                    // this.$root.$refs.toastrRef.handleOpen(); 
                } else {
                    let paramsToastr={
                        isOpen:true,
                        msg:  result.result.message,
                        type:'info'       
                    }
                    this.toastrChangeEvent(paramsToastr);
                    // this.$root.toastrMsg = result.result.message;
                    // this.$root.toastrType = 'info';
                    // this.$root.$refs.toastrRef.handleOpen();
                }
            }

        },

        async getContesterPredictionListData() {
            let params = {
                contestId: this.contestId,
                maxListSize: 10
            }

            const result = await this.getContesterPredictionList(params);
            this.latestPredictionListResult = result.result;
            // var list = this.latestPredictionListResult.slice(this.showFromto, this.showUpto);
            // this.latestPredictionList= list;
            // this.totalPage = Math.ceil(this.latestPredictionListResult.length / this.pageSize);
        },

        resetTeam() {
            this.isJoinedEvent = false;
            this.isBtnSubmitEnable = false;
                
            this.team.teamA.name = '';
            this.team.teamA.id = '';
            this.team.teamA.score = '';

            this.team.teamB.name = '';
            this.team.teamB.id = '';
            this.team.teamB.score = '';
        },

        async getMyPredictionListData() {
            this.resetTeam();

            let params = {
                contestId: this.contestId
            }
           
            const result = await this.getMyPredictionList(params);
         
            if (result.result !== null) {
                  if (result.result.length > 0) {
                    //pass in data if user had participated
                    this.handleJoinedEvent();
                    let returnResult = result.result[0];

                    this.team.teamA.name = returnResult.homeTeam;
                    this.team.teamA.id = returnResult.homeTeamId;
                    this.team.teamA.score =returnResult.homeGoal;

                    this.team.teamB.name = returnResult.awayTeam;
                    this.team.teamB.id = returnResult.awayTeamId;
                    this.team.teamB.score = returnResult.awayGoal;
                } else {
                    //pass in data with url querystring, when user fill up all information after login automatic help participate
                    let queryObject = {};
               
                    let routeQuery = this.$route.query;
             
                    if (Object.keys(routeQuery).length !== 0 && routeQuery.constructor === Object) {
                        queryObject = routeQuery;                      
                    } else if (typeof this.storeDataPath.homeTeamId !== 'undefined') {
                        queryObject = this.storeDataPath;
                    } 

                    if (Object.keys(queryObject).length !== 0) {
                        if (typeof queryObject.redirectFrom !== 'undefined') {
                             this.redirectFrom = queryObject.redirectFrom;
                        }
                       
                        this.team.teamA.name = queryObject.homeTeam;
                        this.team.teamA.id = queryObject.homeTeamId;
                        this.team.teamA.score =queryObject.homeGoal;

                        this.team.teamB.name = queryObject.awayTeam;
                        this.team.teamB.id = queryObject.awayTeamId;
                        this.team.teamB.score = queryObject.awayGoal;
                    
                        this.isBtnSubmitEnable = true;
                     
                        this.handleBtnSubmit();
                    }
                       
                    this.isJoinedEvent = false;
                }
            } 
        
        },

        async getLatestContesterPredictionListData() {

            let params = {
                contestId: this.contestId, 
            }
            const result = await this.getContesterPredictionList(params);
            this.dataPagination.latestPredictionListResult = result.result;
            var list = this.dataPagination.latestPredictionListResult.slice(this.showFromto, this.showUpto);
            this.dataPagination.latestPredictionList= list; 
            this.dataPagination.totalPages = Math.ceil(this.dataPagination.latestPredictionListResult.length / this.pageSize);
            // this.dataPagination.totalPages = this.totalPage; 
            // this.dataPagination.offset = result.result.pageable.offset; 
            // debugger
            // this.dataPagination.totalPages = 7;
            // setTimeout(() => {
            //     this.dataPagination.totalPages = 6
            // }, 2000);
            // this.dataPagination.offset =0;

        },

        handleJoinedEvent() {
             this.isJoinedEvent = true;
             this.isBtnSubmitEnable = false;
        },

        changeTickEvent2(result) {
            this.agreeTermCondition = result;
            this.verifyAllInputFilled();
        },

        async changePaginationEvent(result) {
            //  let params = {
            //     contestId: this.contestId,
            //     pageNo: 1,
            //     pageSize: 5,
            // }
            // params.pageNo = result;
            // params.pageSize = (params.pageSize * result)

            // const results = await this.getLatestContesterPredictionList(params);
            // this.dataPagination.content = results.result.content;
            // debugger;
        }

    },
}
</script>

<style scoped>
.prediction-bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}
.prediction-banner__contest{
    margin-bottom: 1rem;
    height: 11.25rem;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.prediction-banner__prize{
    display: grid;
    grid-template-columns: repeat(2,1fr);
	column-gap: 1rem;
    height: 7.5rem;
}
.prediction-banner__contest.show .prediction-banner__contest-overlay{
    transform: translate(0,0);
}
.prediction-banner__contest img{
    transition: all .3s;
}
.prediction-banner__contest.show img{
     transform: scale(1.2);
}
.prediction-banner__contest-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 0 0 1.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    transform: translate(0,100%);
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:3.125rem;
    text-align: center;
}
.prediction-banner__prize-card{
    height: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}
.prediction-banner__prize-card__overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5rem 0 0 1.5rem;
    font-size: 1.125rem;
    font-weight: 700;
    transform: translate(0,100%);
    transition: all .3s;
    background-color: rgb(0 0 0 / 72%);
}
.prediction-banner__prize-card img{
     transition: all .3s;
}
.prediction-banner__prize-card:hover img{
    transform: scale(1.2);
}
.prediction-banner__prize-card:hover .prediction-banner__prize-card__overlay{
    transform: translate(0,0);
}
.prediction-countdown__wrapper{
    margin-bottom: 2.25rem;
}
.prediction-countdown__title{
    font-size: 1.125rem;
    
}
.prediction-countdown{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
}
.prediction-countdown__time{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-theme);
    padding: 0.5rem;
    border-radius: 0.5rem;
}
.prediction-countdown__time-seperator{
    margin: 0 0.5rem;
}
.prediction-countdown__time-time{
    font-size: 1.375rem;
    font-weight: 700;
}
.prediction-countdown__time-day{
    font-size: 0.6875rem
}
.prediction-entry__title{
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
}
.prediction-entry__desc{
    font-size: 0.6875rem;
    color: var(--color-theme-2);
    margin-bottom: 1.1875rem;
}
.prediction-team__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--color-theme);
    width: 49.625rem;
    padding: 1rem;
    border-radius: 1.5rem;
    margin: 0 auto;
    margin-bottom: 1.9375rem;
    box-sizing: border-box;
}
.prediction-team{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.prediction-team__name{
    margin-bottom: .5rem;
    font-size: 0.875rem;
    font-weight: 700;
}
.prediction-team__choose{
    background-color: black;
    border-radius: 0.5rem;
    width: 6.25rem;
    height: 6.25rem;
     display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .5rem;
    padding: 1rem;
    box-sizing: border-box;
}
.prediction-team__choose:hover{
    background-color: var(--color-blue-20);
}
.prediction-team__desc{
    font-size: 0.6875rem;
    text-align: center;
}
.prediction-team__container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
}
.prediction-team__score-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.9rem;
}
.prediction-team__score{
    border-radius: 0.5rem;
    background-color: black;
    width: 4.125rem;
    height: 4.125rem;
    margin: 0 1.875rem;
    overflow: hidden;
}
.prediction-team__vs{
    font-size: 1.375rem;
    font-weight: bold;
}
.prediction-team__score input{
    width: inherit;
    height: inherit;
    background-color: transparent;
    color: white;
    text-align: center;
    border: none;
    font-weight: 700;
    padding: 0;
    font-size: 1.5rem;
}
.prediction-team__score input::placeholder{
    color: white;
    font-weight: 700;
}
.prediction-team__btn-submit{
    width: 21.25rem;
    margin-bottom: 1.25rem;
    cursor: pointer;
}
.prediction-team__term_condition{
    color: var(--color-blue-20);
    /* text-decoration: underline; */
    cursor: pointer;
}

/* table */
.prediction-table{
    width: 49.625rem;
    border-radius: 0.5rem;
    overflow: hidden;
    margin: 0 auto 5rem;
}
.prediction-table__header{
    background-color: var(--color-theme);
    padding: 0.625rem;
    display: flex;
}
.prediction-table__header-title{
    flex: 1;
    font-size: 0.875rem;
}
.prediction-table__header-title-name{
    padding-left: 7.5rem;
}
.prediction-table__body{
    font-size: 0.6875rem;
    min-height: 20.5rem;
    background-color: rgb(32 32 32 / 60%);
}
.prediction-table__body-list{
    display: flex;
    padding: 0.65625rem;
    position: relative;
    justify-content: center;
    align-items: center;
}

.prediction-table__body-list__divider{
    position: absolute;
    bottom: 0;
    width: 80%;
    height: .08rem;
    background-color: var(--color-grey-60);
}
.prediction-table__body-list__result{
    margin-right: 2rem;
    display: flex;
    align-items: center;
}
.prediction-table__body-list__result-name{
    width: 7rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
    text-align: right;
}
.prediction-table__body-list__result-name-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 7rem;
}
.prediction-table__body-list__result-score-2{
    width: 1.5rem;
    display: block;
    margin-right: 0.5rem;
}

.prediction-table__footer{
    background-color: var(--color-theme);
    padding: 0.625rem;
    display: flex;
    min-height: 1rem;
    align-items: flex-end;
    justify-content: flex-end;
}
.prediction-table__body-list__child-1{
    flex: 1;
    text-align: center;
    overflow: hidden;
}
.prediction-table__body-list__child-2{
    flex: 1;
    text-align: center;
    align-items: center;
}
.prediction-table__no-data{
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}
.term-body{
      max-height: 26.5rem;
    font-size: 0.875rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
    color: var(--color-grey-light);
}
.term-body>span{
    color: var(--color-grey-light);
}
.prediction-modal__team-search__wrapper{
    background-color: var(--color-theme-2);
    border-radius: 1rem;
    box-sizing: border-box;
    padding: 0.1rem;
    position: relative;
    margin-bottom: 1.5rem;
    overflow: hidden;
}
.prediction-modal__team-search__input{
    width: 100%;
    border: none;
    background-color: transparent;
    padding-left: 2.5rem;
    height: 2.1rem;
    color: white;
}
.prediction-modal__team-search__input::placeholder{
    color: white;
}
.prediction-modal__team-search__input:focus{
    outline: none;
}
.prediction-modal__team-search__icon{
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
    position: absolute;
    top: 0.7rem;
    left: 1rem;
    pointer-events: none;
}
.prediction-modal__team-icon__wrapper{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    -moz-column-gap: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
    height: 12.5rem;
    margin-bottom: 1rem;
    /* overflow-y: auto; */
    text-align: center;
    grid-template-rows:  repeat(2,1fr);
}
.prediction-modal__team-icon__container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
     cursor: pointer;
       border-radius: 0.5rem;
    border: 0.15rem solid transparent;
}
.prediction-modal__team-name{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.6875rem;
    height: 2rem;
}
.prediction-modal__team-icon{
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: .5rem;
    padding: 0.4rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    border: 0.15rem solid transparent;
}

.prediction-modal__team-icon.selected{
    border-color: var(--color-hover);
    background-color: var(--color-grey-60);
}
.prediction-modal__team-icon__container:hover .prediction-modal__team-icon{
    border-color: var(--color-hover);
    background-color: var(--color-grey-60);
}
 
  @media (max-width: 768px){
       .prediction-banner__contest {
           height: 7.125rem;
       }
        .prediction-banner__prize{
            height: 5rem;
            column-gap: .5rem;
        }
        .prediction-banner__contest-overlay{
            font-size: 1.375rem;
            padding-left: 0.5rem;
        }
        .prediction-banner__prize-card__overlay{
            font-size: 0.6075rem;
            padding-left: 0.5rem;
        }
        .prediction-modal__team-icon__wrapper{
            grid-template-columns: repeat(3,1fr);
        }
        .prediction-team__wrapper{
            width: 100%;
            margin-bottom: 1.5rem;
        }
        .prediction-table{
             width: 100%;
        }
        .prediction-team__choose{
            width: 2.75rem;
            height: 2.75rem;
            padding: 0.3rem;
        }
        .prediction-team__score{
            width: 2.3rem;
            height: 2.3rem;
            margin-top: 1rem;
        }
        .prediction-team__score input{
            font-size: 1.2rem;
        }
         .prediction-modal__team-icon__container.selected{
            border-color: var(--color-hover);
            background-color: var(--color-grey-60);
        }
        .prediction-modal__team-icon__container:hover{
            border-color: var(--color-hover);
            background-color: var(--color-grey-60);
        }
        .prediction-modal__team-icon.selected{
            border-color: transparent;
            background-color: transparent;
        }
        .prediction-modal__team-icon__container:hover .prediction-modal__team-icon{
            border-color: transparent;
            background-color: transparent;
        }
       .prediction-table__header-title{
           width: 30%;
           flex: none;
       }
        .prediction-table__header-title.title-2{
            width: auto;
            flex: 1;
        }
       .prediction-table__body-list__child-1{
            width: 30%;
            flex: none;
            text-align: center;
            overflow: hidden;
        }
        .prediction-table__body-list__child-2{
            width: 70%;
            flex: none;
            text-align: center;
            align-items: center;
        }
        .prediction-table__body-list__result-name-2{
            width: 5rem;
        }
        .prediction-table__body-list__result-name{
            width: 5rem;
        }
        .prediction-table__body-list__result {
            margin-right: 1rem;
        }
        .prediction-table__header-title-name{
            padding-left: 5.5rem;
        }

    }

</style>